import React, { useState, CSSProperties } from "react";
import { PartnerWidgetProps, ProductComponent } from "../types";

interface Styles {
  wrapper: CSSProperties;
}

export const PartnerWidgetFluidRow = ({
  productImage,
  productImageAltTag,
  productTitle,
  rateRange,
  description,
  id,
  isExpanded,
  disclosureText,
  buttonLink,
  buttonText,
  handleExpandArea,
  handleCloseArea,
}: ProductComponent): JSX.Element => {
  return (
    <div style={stylesRow.wrapper}>
      <div style={stylesRow.productSection} data-testid="mobile-product-row">
        <div style={stylesRow.firstCol}>
          <img
            src={productImage}
            alt={productImageAltTag}
            height={80}
            width={80}
          />
          <p style={stylesRow.productTitle}>{productTitle}</p>
        </div>

        <div>
          <p style={stylesRow.pTextStyles}>
            <span style={stylesRow.subtitleText}> Rate Range:</span>
            <div dangerouslySetInnerHTML={{__html: rateRange}} />
          </p>
        </div>

        <div>
          <div style={stylesRow.descriptionTextStyles} dangerouslySetInnerHTML={{__html: description}} />
        </div>

        <div style={stylesRow.firstCol}>
          <a
              href={buttonLink}
              style={stylesRow.buttonStyle}
              className="fourth-col__button-text"
            >
              {buttonText}
          </a>
  
          <button
            data-testid="disclosures-button"

            style={stylesRow.expandButtonText}
            onClick={() => handleExpandArea(id)}
          >
            {isExpanded ? "Hide Disclosures" : "View Disclosures"}
          </button>
        </div>
      </div>
      {isExpanded ? (
        <div className="expandedArea">
          <div></div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1%",
            }}
          >
            <button
              style={{
                backgroundColor: "#ffffff",
                border: "none",
                cursor: "pointer",
                marginRight: "2.5%",
                fontSize: 14,
                textAlign: "right",
                textDecorationLine: "underline",
              }}
              onClick={handleCloseArea}
            >
              Close
            </button>
          </div>
          <div style={stylesRow.expandedArea}>
            <div dangerouslySetInnerHTML={{__html: disclosureText}} />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

/**
 * Handles the 
 * @param param0 
 * @returns 
 */
export const PartnerWidgetFluid = ({
  products,
}: PartnerWidgetProps): JSX.Element => {
  const [expandedArea, setExpandedArea] = useState<string|null>(null);

  function handleExpandArea(id: string) {
    setExpandedArea((prev) => (prev === id ? null : id));
  }

  function handleCloseArea() {
    setExpandedArea(null);
  }

  if (!products) {
    return (
      <div data-testid="error-message">We have no results.</div>
    );
  }

  return (
    <div
      style={styles.wrapper}
      data-testid="mobile-widget"
      key="mobile-container"
    >
      {products.map((item) => (
        <PartnerWidgetFluidRow
          key={item.key}
          productImage={item.productImage}
          productImageAltTag={item.productImageAltTag}
          productTitle={item.productTitle}
          rateRange={item.rateRange}
          description={item.description}
          id={item.id}
          buttonText="Learn more"
          isExpanded={expandedArea === item.id}
          buttonLink={item.buttonLink}
          handleExpandArea={handleExpandArea}
          handleCloseArea={handleCloseArea}
          disclosureText={item.disclosureText}
          disclosureButtonHideText={item.disclosureButtonHideText}
          disclosureButtonText={item.disclosureButtonText}
        />
      ))}
    </div>
  );
};

const styles: Styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    borderWidth: 1,
    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
};

const stylesRow: Record<string, React.CSSProperties> = {
  wrapper: {
    borderBottom: "1px solid var(--slmw-primary, #134f82)",
    paddingBottom:20,
    paddingTop:20,
    width: "100%"
  },
  borderLine: {
    display: "flex",
    borderWidth: 0,
    marginTop: 20,
    marginBottom: 20,
    borderBottomWidth: 1,
    width: "70%",
    borderStyle: "solid",
    borderColor: "#134f82",
  },

  productSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
  },
  
  productTitle: {
    fontSize: 15,
    fontWeight: "600",
    verticalAlign: "middle",
    textAlign: "center",
    fontFamily: "sans-serif",
  },

  subtitleText: {
    fontSize: 13,
    fontWeight: "400",
    textAlign: "center",
    fontFamily: "sans-serif",
  },

  pTextStyles: {
    fontSize: 14,
    fontWeight: "600",
    textAlign: "center",
    fontFamily: "sans-serif",
  },

  descriptionTextStyles: {
    fontSize: 13,
    textAlign: "center",
    fontFamily: "sans-serif",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 20,
    maxWidth: 300,
  },

  firstCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 13,
    verticalAlign: "middle",
    fontFamily: "sans-serif",
  },

  buttonStyle: {
    fontSize: 13,
    fontWeight: "bold",
    color: "var(--slmw-secondary, #ffffff)",
    backgroundColor: "var(--slmw-primary, #134f82)",
    boxShadow:"none",
    border: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer",
    borderRadius: 50,
    width: "50%",
    paddingTop: 10,
    paddingBottom: 10,
    justifyContent: "center",
  },

  expandButtonText: {
    fontSize: 12,
    marginTop: 20,
    verticalAlign: "middle",
    fontFamily: "sans-serif",
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    padding: 0,
    cursor:"pointer",
    textDecoration:"underline"
  },

  expandedArea: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },

  expandedTitle: {
    fontSize: 14,
    fontWeight: 600,
  },

  expandedAreaHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },

  expandedAreaHeaderTitle: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: 30,
    fontWeight: "bold",
  },

  expandedAreaHeaderClose: {
    display: "flex",
    alignItems: "flex-start",
    textDecorationLine: "underline",
    verticalAlign: "middle",
    fontFamily: "sans-serif",
    marginTop: 5,
    borderWidth: 0,
    padding: 0,
  },

  expandedAreaTextContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
};