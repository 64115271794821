import React, { useEffect, useState } from "react";
import "./App.css";
import { PartnerWidgetFluid } from "./components/PartnerWidgetFluid";
import { PartnerWidgetTable} from "./components/PartnerWidgetTable";
import { HubDbProductRowResponse, PartnerHubDbRowResponse, RowContentProps } from "./types";

/**
 * Get partner from HubDb
 * @returns 
 */
async function getPartner(partnerIdentifier: string ) : Promise<PartnerHubDbRowResponse>  {
	const request = await fetch(`https://api.hubapi.com/cms/v3/hubdb/tables/loan_widget__partners/rows?portalId=2241837&identifier=${partnerIdentifier}`);
	const json = await request.json();

	const output = json.results[0] as PartnerHubDbRowResponse;
	return output;
}


/**
 * Takes the default Product URL and add the new partner specific params.
 * If the same one is supplied, it'll override it.
 * @param input 
 * @param partner 
 * @returns 
 */
function processLink(input : string, partner: PartnerHubDbRowResponse) {
	const parsed = new URL(input);
	const partnerSpecific = new URLSearchParams(partner.values.default_params);
	for (const [key, val] of partnerSpecific.entries()) {
		parsed.searchParams.set(key, val);
	}
	return parsed.toString();
}


interface AppComponent {
	partnerId: string;
}
const App = ({ partnerId } : AppComponent) => {
	const [isMobile, setIsMobile] = useState(false);
	const [products, setProducts] = useState<RowContentProps[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	console.log(isLoading);
	// Get Products.
	useEffect(() => {
		// Get products.
		async function getProducts() {

			setIsLoading(true);

			// First we get the Partner.
			const partner = await getPartner(partnerId);

			const request = await fetch("https://api.hubapi.com/cms/v3/hubdb/tables/loan_widget__products/rows?portalId=2241837");
			const json = await request.json();
			
			const results = json.results as HubDbProductRowResponse[];

			setProducts(results.map(item => ({
				id: item.id,
				key: item.id,
				productTitle: item.values.name,
				productImage: item.values.icon_file.url,
				productImageAltTag: "",
				rateRange: item.values.rate_range,
				description: item.values.description,
				buttonText: "Learn more",
				buttonLink: processLink(item.values.url, partner),
				disclosureText: item.values.disclosure_text,
				disclosureButtonText: "View Disclosures",
				disclosureButtonHideText: "Hide Disclosures",
				sortValue: item.values.sort_by
			})).sort((a, b) => a.sortValue - b.sortValue));
		}

		// async workaround
		getProducts();
	}, [partnerId]);


	/**
	 * Handle resizing window. This is not optimial, 
	 * TODO: change to window.matchMedia
	 */
	const handleResize = () => {
		if (window.innerWidth < 750) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	// Handle resizing of screen.
	useEffect(() => {
		// First invocation:
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	// Show a mobile device?
	if (isMobile) {
		return <PartnerWidgetFluid products={products} />;
	}
	return <PartnerWidgetTable products={products} />;
};

export default App;
