import React, { useState } from "react";
import { ProductComponent, PartnerWidgetProps, Styles } from "../types";

const PartnerWidgetComponent = ({
  productImage,
  productImageAltTag,
  productTitle,
  rateRange,
  description,
  id,
  isExpanded, 
  disclosureText,
  buttonLink,
  buttonText,
  handleExpandArea,
  handleCloseArea,
  disclosureButtonText,
  disclosureButtonHideText,
}: ProductComponent): JSX.Element => {
  return (
    <>
      <tr style={styles.productRow} data-testid="browser-product-row">
        <td style={styles.cell}>
          <div style={styles.firstCol}>
            <img
              src={productImage}
              alt={productImageAltTag}
              height={40}
              width={40}
            />
            <div style={{fontSize:12, paddingTop:10}}>{productTitle}</div>
          </div>
        </td>
        <td style={styles.cell}>
          <div style={styles.firstCol}>
            <div dangerouslySetInnerHTML={{__html: rateRange}} />
          </div>
        </td>
        <td style={styles.cell}>
          <div
            style={styles.descriptionTextStyles}
            data-testid="browser-product-description"
            dangerouslySetInnerHTML={{__html: description}}
          />
          
        </td>
        <td style={styles.cell}>
          <div style={styles.fourthCol}>
            <a
              href={buttonLink}
              style={styles.buttonStyle}
            >
              {buttonText}
            </a>
            <button
              style={styles.expandButtonText}
              onClick={() => handleExpandArea(id)}
            >
              {isExpanded ? disclosureButtonHideText: disclosureButtonText}
            </button>
          </div>
        </td>
      </tr>
      {isExpanded && (
        <tr style={{backgroundColor:"#ffffff"}}>
          <td colSpan={4}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingLeft:20,
                paddingRight:20
              }}
            >
              <button
                style={{
                  backgroundColor: "#ffffff",
                  border: "none",
                  cursor: "pointer",
                  marginRight: "2.5%",
                  fontSize: 14,
                  textAlign: "right",
                  textDecorationLine: "underline",
                }}
                onClick={handleCloseArea}
              >
                Close
              </button>
            </div>
            <div style={styles.expandedArea}>
              <div dangerouslySetInnerHTML={{__html: disclosureText}} />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

/**
 * Entry point for Larger screens. This wraps the rows above.
 * @param param0 
 * @returns 
 */
export const PartnerWidgetTable = ({
  products = []
}: PartnerWidgetProps): JSX.Element => {
  const [expandedArea, setExpandedArea] = useState<string | null>(null);

  function handleExpandArea(id: string) {
    setExpandedArea((prev) => (prev === id ? null : id));
  }

  function handleCloseArea() {
    setExpandedArea(null);
  }

  if (products.length === 0) {
    return (
      <div data-testid="error-message">No results found.</div>
    );
  }

  return (
    <div style={styles.componentWrapper} data-testid="browser-widget">
      <table style={styles.table}>
        <tbody>
          <tr style={styles.headerRow}>
            <th style={styles.headerCell}>Product</th>
            <th style={styles.headerCell}>Rate Range</th>
            <th style={styles.headerCell}>Description</th>
            <th style={styles.headerFourthCol}>&nbsp;</th>
          </tr>

          {products.map((item) => (
            <PartnerWidgetComponent
              productImage={item.productImage}
              productImageAltTag={item.productImageAltTag}
              productTitle={item.productTitle}
              rateRange={item.rateRange}
              description={item.description}
              buttonLink={item.buttonLink}
              buttonText={item.buttonText}
              id={item.id}
              key={item.key}
              disclosureText={item.disclosureText}
              handleExpandArea={handleExpandArea}
              isExpanded={expandedArea === item.id}
              handleCloseArea={handleCloseArea}
              disclosureButtonHideText={item.disclosureButtonHideText}
              disclosureButtonText={item.disclosureButtonText}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const styles : Styles = {
  table: {
    borderCollapse: "collapse", 
    width: "100%",
    backgroundColor:"var(--slmw-background, transparent)"
  },
  componentWrapper: {
    display: "flex",
    flexDirection: "column",
    borderWidth: 1,
    borderColor: "#000000",
    justifyContent: "center",
    alignItems: "center",
  },
  rowLayout: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  rowLayoutChild: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingTop: 25,
    paddingBottom: 5,
  },
  rowLayoutChildHover: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingTop: 25,
    paddingBottom: 5,
  },
  rowLayoutChildExpanded: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingTop: 25,
    paddingBottom: 5,
  },
  expandedArea: {
    paddingLeft: "2.5%",
    paddingRight: "2.5%",
  },
  expandedAreaHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },

  expandedAreaHeaderTitle: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: 30,
    fontWeight: "bold",
  },
  expandedAreaHeaderClose: {
    display: "flex",
    alignItems: "flex-start",
    textDecorationLine: "underline",
    verticalAlign: "middle",
    fontFamily: "sans-serif",
    marginTop: 5,
    borderWidth: 0,
    padding: 0,
  },
  expandedAreaTextContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },

  headerRow: {
    width: "100%",
    justifyContent: "space-between",
    backgroundColor: "var(--slmw-primary, #134f82)",
  },
  headerCell: {
    paddingTop: 20,
    paddingBottom: 20,
    color: "var(--slmw-secondary, #ffffff)",
    paddingLeft:10,
    paddingRight:10,
    textAlign: "center"
  },
  headerFourthCol: {
    paddingTop: 20,
    paddingBottom: 20,
    color: "#ffffff",
    width: "25%",
    minWidth: 150,
  },
  firstCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 13,
    fontFamily: "sans-serif",
    verticalAlign: "middle",
    textAlign: "center" as const,
  },
  secondCol: {
    display: "flex",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    width: "20%",
    justifyContent: "center",
    fontSize: 13,
    verticalAlign: "middle",
    fontFamily: "sans-serif",
  },
  fourthCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    height: "100%",
    width: "100%",
    minWidth: 200,
    fontSize: 12,
    verticalAlign: "middle",
    fontFamily: "sans-serif",
  },
  buttonStyle: {
    fontSize: 13,
    fontWeight: "bold",
    color: "var(--slmw-secondary, #ffffff)",
    backgroundColor: "var(--slmw-primary, #134f82)",
    borderRadius: 50,
    width: "80%",
    textAlign:"center",
    display:"block",
    textDecoration:"none",
    paddingTop: 10,
    paddingBottom: 10,
    textTransform:"uppercase",
    justifyContent: "center",
    cursor: "pointer",
  },

  descriptionTextStyles: {
    fontSize: 13,
    verticalAlign: "middle",
    paddingLeft: 20,
    paddingRight: 20,
    fontFamily: "sans-serif",
  },
  expandButtonText: {
    fontSize: 13,
    verticalAlign: "middle",
    fontFamily: "sans-serif",
    marginTop: 5,
    backgroundColor: "#ffffff00",
    borderWidth: 0,
    padding: 0,
    cursor: "pointer",
  },
  expandedTitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  hidden: {
    display: "hidden",
  },
  cell: {
    paddingTop:10,
    paddingBottom:10
  }
};

