import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";


interface Props {
	target: HTMLElement|null;
	id: string;
}


/**
 * We want the team to be able to turn this on.
 * @param props 
 * @returns 
 */
export function RateWidget(props : Props) : void {
	if (!props.target) {
		console.error("Missing target prop.");
		return;
	}

	const styleElement = document.createElement( "style" );
	styleElement.innerHTML = `* { 
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
table tr:hover {
	background-color: #b7b7b730;
}
.expandedArea:hover {
	background-color: #ffffff;
}
p {
	font-size: 12px;
}
li {
	font-size: 12px;
}`;

	const host = props.target;
	const shadow = host.attachShadow({mode: "open"});
	shadow.appendChild(styleElement);

	const renderWithin = document.createElement("div");
	shadow.appendChild(renderWithin);
	const root = ReactDOM.createRoot(renderWithin);

	root.render(
		<React.StrictMode>
			<App partnerId={props.id} />
		</React.StrictMode>
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
